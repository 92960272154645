<template>
  <v-row class="contact-detail pt-2">
    <v-col md="12" sm="12" class="pt-0">
      <div class="new-white-box-overview-main nav">
        <div class="col-md-12 px-0 pt-0">
          <v-skeleton-loader
            class="custom-skeleton"
            v-if="pageLoading"
            type="text@5"
          >
          </v-skeleton-loader>
          <table v-else class="width-100">
            <tr>
              <td
                class="py-1 font-size-18 font-weight-500 text-capitalize text-nowrap"
                width="100"
              >
                Contact #
              </td>
              <td class="py-1 font-size-18 text-capitalize">
                <v-skeleton-loader
                  class="custom-skeleton"
                  v-if="pageLoading"
                  type="button"
                >
                </v-skeleton-loader>
                <Barcode v-else :barcode="getBarcode"></Barcode>
              </td>
            </tr>
            <!-- <tr>
              <td class="py-1 font-size-18 text-capitalize font-weight-500">
                Security Code
              </td>
              <td class="py-1 font-size-18 text-capitalize">
                {{ contact.security_code }}
              </td>
            </tr> -->
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Name
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="fullname">
                  {{ fullname }}
                </template>
                <template v-else><em class="text-muted">No name</em></template>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Company
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="contact.company_name">{{
                  contact.company_name
                }}</template>
                <template v-else
                  ><em class="text-muted">No company name</em></template
                >
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Display Name
              </td>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize"
                v-if="contact && contact.display_name"
              >
                {{ contact.display_name }}
              </td>
              <em v-else class="text-muted"> no display name</em>
            </tr>
            <tr>
              <td valign="top" class="py-1 font-size-18 font-weight-500">
                UEN
              </td>
              <td valign="top" class="py-1 font-size-18 text-lowercase">
                <template v-if="contact.uen">{{ contact.uen }}</template>
                <template v-else><em class="text-muted">No uen</em></template>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Website
              </td>
              <td valign="top" class="py-1 font-size-18 text-lowercase">
                <template v-if="contact.website">{{
                  contact.website
                }}</template>
                <template v-else
                  ><em class="text-muted">No Website</em></template
                >
              </td>
            </tr>
            <tr>
              <td class="py-1 font-size-18 text-capitalize font-weight-500">
                Category
              </td>
              <td class="py-1 font-size-18 text-capitalize">
                <template v-if="contact.category">
                  <v-chip
                    :color="contact?.category_color"
                    small
                    label
                    outlined
                    >{{ contact.category }}</v-chip
                  >
                </template>
                <template v-else
                  ><em class="text--secondary">No category</em></template
                >
              </td>
            </tr>
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Contact Details
              </th>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Email
              </td>
              <td valign="top" class="py-1 font-size-18">
                <template v-if="contact && contact.company_email">
                  {{ contact.company_email }}
                </template>
                <em v-else class="text-muted"> no email</em>
              </td>
            </tr>

            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Mobile No.
              </td>
              <td class="py-1 font-size-18 text-capitalize">
                <template v-if="contact && contact.company_number">
                  {{ contact.company_number }}
                </template>
                <em v-else class="text-muted"> no mobile number</em>
              </td>
            </tr>

            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Did
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="contact.company_fax">{{
                  contact.company_fax
                }}</template>
                <template v-else><em class="text-muted">No did</em></template>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Designation
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="contact.desigantion">{{
                  contact.desigantion
                }}</template>
                <template v-else
                  ><em class="text-muted">No Designation</em></template
                >
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Department
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="contact.department">{{
                  contact.department
                }}</template>
                <template v-else
                  ><em class="text-muted">No department</em></template
                >
              </td>
            </tr>
            <tr v-if="contact?.tags?.length">
              <th
                colspan="2"
                class="grey lighten-4 font-size-19 color-custom-blue py-1"
              >
                Tags
              </th>
            </tr>
            <tr v-if="contact.tags && contact.tags.length > 0">
              <td class="font-size-18 py-1" colspan="2">
                <template v-if="contact.tags && contact.tags.length > 0">
                  <v-chip
                    small
                    v-for="(row, index) in contact.tags"
                    :key="index"
                    class="mr-2"
                    text-color="white"
                    :color="row.color"
                    >{{ row.text }}</v-chip
                  >
                </template>
                <em v-else class="text-muted"> no tags</em>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
// import Status from "@/view/pages/partials/Status.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";

export default {
  name: "contact-details",
  title: "Supplier",
  mixins: [CommonMixin],
  props: {
    contact: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    pageLoading: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getDetailType(param) {
      if (param === 1) {
        return "Email";
      }
      return "Phone";
    },
  },
  components: {
    // Status,
    Barcode,
  },
  computed: {
    supplierActivated() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.contact) === false) {
        return _this.contact.activated;
      }
      return false;
    },
    fullname() {
      let name = "";
      const _this = this;
      if (
        _this.contact &&
        _this.lodash.isEmpty(_this.contact.persons) === false
      ) {
        for (let i = 0; i < _this.contact.persons.length; i++) {
          if (_this.contact.persons[i].default) {
            name =
              _this.lodash.startCase(_this.contact.persons[i].title) +
              ". " +
              _this.contact.persons[i].first_name +
              " " +
              _this.contact.persons[i].last_name;
          }
        }
      }
      return name;
    },
    primaryEmail() {
      let email = "";
      const _this = this;
      if (
        _this.contact &&
        _this.lodash.isEmpty(_this.contact.details) === false
      ) {
        for (let i = 0; i < _this.contact.details.length; i++) {
          if (_this.contact.details[i].type === 1) {
            email = _this.contact.details[i].value;
            break;
          }
        }
      }
      return email;
    },
    primaryPhone() {
      let phone = "";
      const _this = this;
      if (
        _this.contact &&
        _this.lodash.isEmpty(_this.contact.details) === false
      ) {
        for (let i = 0; i < _this.contact.details.length; i++) {
          if (_this.contact.details[i].type === 2) {
            phone = _this.contact.details[i].value;
            break;
          }
        }
      }
      return phone;
    },
    getProfileImage() {
      if (!this.contact.profile_logo) {
        return null;
      }
      return this.contact.profile_logo.file.url;
    },
    getBarcode() {
      if (!this.contact.barcode) {
        return "";
      }
      return this.contact.barcode;
    },
  },
};
</script>
